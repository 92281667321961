'use client';

import { useSearchParams } from 'next/navigation';

import { useMemo, useState } from 'react';

import { Button, Image } from '@mantine/core';

import { sendEvent } from '@/shared/analytics';

export const GoogleButton = () => {
  const searchParams = useSearchParams();
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  let redirectPath = searchParams.get('redirect_path') || '/';
  redirectPath = redirectPath.startsWith('http') ? '/' : redirectPath;
  redirectPath = redirectPath.startsWith('/')
    ? redirectPath
    : `/${redirectPath}`;

  const externalUrl = useMemo(() => {
    if (typeof window === 'undefined') {
      return '';
    }

    return `${location.origin}/api/auth/login/?redirect_path=${redirectPath}`;
  }, [redirectPath]);

  const handleLogin = () => {
    sendEvent({ event_name: 'click_authorization', source: 'google' });
    setIsLoggingIn(true);
    window.location.href = externalUrl;
  };

  return (
    <Button
      fullWidth
      color="gray"
      loading={isLoggingIn}
      variant="outline"
      leftSection={
        <Image alt="google" h={24} radius="xl" src="/icons/google.svg" w={24} />
      }
      onClick={handleLogin}
    >
      Sign In with Google
    </Button>
  );
};
