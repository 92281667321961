'use client';

import { useRouter } from 'next/navigation';

import { useEffect } from 'react';

type ErrorResponderProps = {
  error?: string;
};
export const ErrorResponder = ({ error }: ErrorResponderProps) => {
  const router = useRouter();

  useEffect(() => {
    const abortController = new AbortController();
    if (error === 'unauthorized') {
      fetch('/api/auth/login/clear_cookies/', {
        method: 'POST',
        signal: abortController.signal,
      }).finally(() => {
        router.push('/login/');
      });
    }

    return () => {
      abortController.abort();
    };
  }, [error, router]);

  return null;
};
